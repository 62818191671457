import React, { useEffect, useState } from 'react';
import s from '@/styles/layout/LocationPan.module.scss';
import { useRouter } from 'next/router';
import { useGlobalData } from '@/context/GlobalContext';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { useMyLocationQuery } from '@/modules/locations';
import { getInitialCityInfo, setInitialCityInfo } from '@/modules/locations/helpers';
import LocationModal from './LocationModal';

function LocationPan({
  buttonref, dropdown, state, isOpen,
}) {
  const [selectedCity, setSelectedCity] = useState('');
  const router = useRouter();
  const { homeCity, setHomeCity } = useGlobalData();
  const { setCityPrefix } = useCityPrefix();
  const [isFlipped, setIsFlipped] = useState(false);
  const { locale } = useRouter();

  const [location, setLocation] = useState(null);
  const [myLocationCity, setMyLocationCity] = useState(null);

  const cityTranslations = {
    pattaya: {
      ru: 'Паттайя',
      en: 'Pattaya',
    },
    phuket: {
      ru: 'Пхукет',
      en: 'Phuket',
    },
    krabi: {
      ru: 'Краби',
      en: 'Krabi',
    },
    samui: {
      ru: 'Самуи',
      en: 'Samui',
    },
    pangan: {
      ru: 'Панган',
      en: 'Pangan',
    },
    allcities: {
      ru: 'Все города',
      en: 'All cities',
    },
    bangkok: {
      ru: 'Бангкок',
      en: 'Bangkok',
    },
  };

  const translateCityName = (cityKey, locale) => cityTranslations[cityKey.toLowerCase()]?.[locale] || cityKey.toLowerCase();

  const getCityPath = (city) => {
    switch (city) {
      case 'Паттайя':
        return 'pattaya';
      case 'Пхукет':
        return 'phuket';
      case 'Краби':
        return 'krabi';
      case 'Самуи':
        return 'samui';
      case 'Панган':
        return 'pangan';
      case 'Все города':
        return 'allcities';
      case 'Bangkok':
        return 'bangkok';
      default:
        return 'pattaya';
    }
  };

  const handleSelectCity = async (city) => {
    const cityPath = getCityPath(city);
    setInitialCityInfo({ city: cityPath, userSet: true });
    setSelectedCity(translateCityName(cityPath, locale));
    setHomeCity(cityPath);
    setCityPrefix(cityPath);

    if (router.pathname.startsWith('/[city]')) {
      const parts = router.asPath.split('/');
      parts[1] = cityPath;
      const [path, queryString] = parts.join('/').split('?');
      const params = new URLSearchParams(queryString || '');
      /**
       * В фильтрах используются city и district для переопределения района и города для конкретного фильтра.
       * По этому при глобальном изменении города нужно очищать эти query, чтобы фильтры по локации тоже сбросились
       */
      params.delete('city');
      params.delete('district');

      await router.push(params.toString() ? `${path}?${params.toString()}` : path);
    }

    isOpen();
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (_) => {
            setLocation(null);
          },
        );
      } else {
        setLocation(null);
      }
    };

    getLocation();
  }, []);

  const { data: myLocationData } = useMyLocationQuery(location);

  useEffect(() => {
    const fetchMyLocation = () => {
      const { city } = myLocationData;
      const { userSet } = getInitialCityInfo();

      // If the city was not selected by the user, update the city value in localStorage and the userSet state
      if (!userSet) {
        setInitialCityInfo({ city, userSet: false });
        setMyLocationCity(city);
      }
    };

    if (location && myLocationData) {
      fetchMyLocation();
    }
  }, [location, myLocationData]);

  useEffect(() => {
    const { city: savedCity } = getInitialCityInfo();
    const savedCityName = translateCityName(savedCity, locale);

    setSelectedCity(savedCityName);
    setHomeCity(savedCity);
    setCityPrefix(savedCity);
  }, [locale, router.asPath, myLocationCity]);

  return (
    <div className={s.container_loc}>
      <button className={s.loc_block} onClick={isOpen} ref={buttonref} type="button">
        <img
          className={s.icon}
          src="/assets/icons/location.svg"
          alt="location icon"
        />
        <span>{selectedCity}</span>
        <span className={s.location_mobile}>
          {selectedCity === 'Все города' || selectedCity === 'All cities'
            ? locale === 'en'
              ? 'All'
              : 'Все'
            : selectedCity}
        </span>

        <div className={s.arrow}>
          <img
            src="/assets/icons/arrow.svg"
            alt="icon_error"
            className={isFlipped ? s.is_active : s.is_not_active}
          />
        </div>
      </button>
      {state && (
        <LocationModal
          selectedCity={selectedCity}
          dropdown={dropdown}
          handleSelectCity={handleSelectCity}
        />
      )}
    </div>
  );
}

export default LocationPan;
