import React, { useEffect } from 'react';
import s from '@/styles/layout/LocationModal.module.scss';
import { useLocationData } from '@/hooks/admin/useLocationData';
import Loading from '@/components/partials/Loading';
import { useRouter } from 'next/router';

function LocationModal({
  selectedCity,
  dropdown,
  handleSelectCity,
  handleButtonClick,
}) {
  const { fetchActiveLocations, activateLocations, isLoading } = useLocationData();

  useEffect(() => {
    fetchActiveLocations();
  }, []);

  const { locale } = useRouter();

  const cityTranslations = {
    Паттайя: {
      ru: 'Паттайя',
      en: 'Pattaya',
    },
    Пхукет: {
      ru: 'Пхукет',
      en: 'Phuket',
    },
    Краби: {
      ru: 'Краби',
      en: 'Krabi',
    },
    Самуи: {
      ru: 'Самуи',
      en: 'Samui',
    },
    Панган: {
      ru: 'Панган',
      en: 'Pangan',
    },
    Все: {
      ru: 'Все города',
      en: 'All cities',
    },
    Bangkok: {
      ru: 'Бангкок',
      en: 'Bangkok',
    },
  };

  const translateCityName = (cityKey) => {
    const translationEntry = cityTranslations[cityKey];
    return translationEntry ? translationEntry[locale] || cityKey : cityKey;
  };

  return (
    <div className={s.location_modal} ref={dropdown}>
      <div className={s.current_location}>
        <span onClick={handleButtonClick}>
          <img src="/assets/icons/location.svg" alt="icon_error" />
          {translateCityName(selectedCity)}
        </span>
        <span className={s.location_mobile}>
          <img src="/assets/icons/location.svg" alt="icon_error" />
          {selectedCity === 'Все города' || selectedCity === 'All cities'
            ? locale === 'en'
              ? 'All'
              : 'Все'
            : selectedCity}
        </span>
        <img
          src="/assets/icons/arrow.svg"
          alt="icon_error"
          onClick={handleButtonClick}
        />
      </div>
      <p onClick={() => handleSelectCity('Все города')}>
        {translateCityName('Все')}
      </p>
      {activateLocations.map((city) => (
        <p key={city.id} onClick={() => handleSelectCity(city.name)}>
          {translateCityName(city.name)}
        </p>
      ))}
    </div>
  );
}

export default LocationModal;
