import { useCallback } from 'react';

interface ConsentDataTypes {
  ad_storage: 'granted' | 'denied';
  ad_user_data: 'granted' | 'denied';
  ad_personalization: 'granted' | 'denied';
  analytics_storage: 'granted' | 'denied';
  functionality_storage: 'granted' | 'denied';
  personalization_storage: 'granted' | 'denied';
  security_storage: 'granted';
}
export const DEFAULT_CONSENT: ConsentDataTypes = {
  ad_storage: 'granted',
  ad_user_data: 'granted',
  ad_personalization: 'granted',
  analytics_storage: 'granted',
  functionality_storage: 'granted',
  personalization_storage: 'granted',
  security_storage: 'granted',
};

export const useGoogleConsent = () => {
  /**
   * Функция updateConsent используется для обновления настроек согласия Google.
   * @param {string} consent - всегда строка 'consent'. Нужна чтобы google тег понял что мы работаем именно с политикой согласия.
   * @param {string} eventType - 'default' для установки значения по умолчанию, 'update' для обновления настроек.
   * @param {Partial<ConsentDataTypes>} consentData - объект с настройками согласия.
   * */
  const updateConsent = useCallback((consent: Partial<ConsentDataTypes>) => {
    const storedConsent = localStorage.getItem('consentMode');
    const currentConsent: ConsentDataTypes = storedConsent
      ? JSON.parse(storedConsent)
      : DEFAULT_CONSENT;

    const updatedConsent: ConsentDataTypes = {
      ...currentConsent,
      ...consent,
    };

    if (window.gtag && typeof window.gtag === 'function') {
      window.gtag('consent', 'update', updatedConsent);
    }

    localStorage.setItem('consentMode', JSON.stringify(updatedConsent));
  }, []);

  return { updateConsent };
};
