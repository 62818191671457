import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic'; // Import dynamic from next/dynamic
import useUserAccount from '@/hooks/users/useUserAccount';
import { useRouter } from 'next/router';
import { GlobalProvider } from '@/context/GlobalContext';
import Head from 'next/head';
import { useAuthContext } from '@/modules/auth';
import { isServerSide } from '@/modules/shared/helpers';
import Header from './Header';
import Footer from './Footer';
import AdminSidebar from '../admin/AdminSidebar';
import UserSidebar from './Account/UserSidebar';
import ModeratorAside from './moderator/ModeratorAside';
import { Mobile } from './Mobile.component';

const DynamicCookieModal = dynamic(
  () => import('@/components/partials/Cookie'),
  {
    ssr: false,
  },
);

export default function Layout({ children }) {
  const router = useRouter();
  const isAdminPath = router.pathname.startsWith('/admin');
  const isModeratorPath = router.pathname.startsWith('/moderator');
  const isUserPath = router.pathname.startsWith('/user/');
  const { userData } = useUserAccount();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const { utm_term, oid, company } = router.query;

    if (utm_term && oid && company && !isAuthenticated) {
      localStorage.setItem('utm_term', utm_term);
      localStorage.setItem('oid', oid);
      localStorage.setItem('company', company);
    }
  }, [router.query, isAuthenticated]);

  const isLocalStorageAvailable = !isServerSide() && window.localStorage;
  const showModalInitially = isLocalStorageAvailable
    ? localStorage.getItem('showModal') !== 'false'
    : true;
  const [showModal] = useState(showModalInitially);

  return (
    <div className="global_container">
      <GlobalProvider>
        <Head>
          <link rel="icon" href="/favicon.png" />
        </Head>
        <Header />
        {showModal && <DynamicCookieModal />}

        <main className="main_sda">
          <main
            className={
              isAdminPath || isUserPath || isModeratorPath
                ? 'layout_container'
                : 'container'
            }
          >
            {userData?.is_admin && isAdminPath && <AdminSidebar />}
            {isUserPath && <UserSidebar />}
            {userData?.is_moderator && isModeratorPath && <ModeratorAside />}
            {children}
          </main>
        </main>

        <Footer />
        {isMobile && (
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              zIndex: 1000,
            }}
          >
            <Mobile />
          </div>
        )}
      </GlobalProvider>
    </div>
  );
}
